<template>
<Toast />
<NavigationBar
    :class="{ scrolled: isScrolled }"
/>
<slot />
</template>

<script lang="ts">
</script>

<style lang="scss">
*, *::before, *::after {
    box-sizing: border-box;
}

html {
    background: var(--shade-100);
}

#app {
    display: block;
    color: var(--neutral-200);
    font-family: Inter;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

</style>
